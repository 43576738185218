<template>
  <div>
    <a-button type="dashed" block  @click="showModal">
        <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z" fill="#111827"/>
        </svg>
        Thêm Security Group
    </a-button>
    <a-modal v-model="visible" title="Tạo mới Security Group">
        <a-form :form="securityGroup" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
            <a-form-item :label="$t('name')">
                <a-input
                    v-decorator="['name', { rules: [{ required: true, message: 'Bạn chưa nhập tên!' }] }]"
                />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button 
                    type="primary" 
                    html-type="submit"
                    :loading="fetchCreate"
                    >{{ $t('create') }}
                </a-button>
            </a-form-item> 
        </a-form>
        <template #footer></template>
    </a-modal>
  </div>
</template>

<script>
import createSecurityGroup from "@/services/network/createSecurityGroup"
import { notification } from 'ant-design-vue'

export default {
    data() {
        return {
            visible: false,
            securityGroup: this.$form.createForm(this, { name: 'securityGroup' }),
            fetchCreate: false,
        };
    },
    methods: {
        showModal() {
            this.visible = true;
        },
        handleSubmit(e) {
            e.preventDefault();
            this.securityGroup.validateFields((err, values) => {
                if (err) {
                    return;
                }
                this.fetchCreate = true
                createSecurityGroup(values)
                .then(res => {
                    this.fetchCreate = false
                    if (this._.isObject(res)) {
                        this.visible = false
                        notification.success({
                            message: 'Tạo Security Group thành công'
                        })
                        this.$emit('reload')
                    } else {
                        notification.error({
                            message: res.message
                        })
                    }
                })
            });
        },
    },
};
</script>